<template>
  <v-autocomplete
    data-cy="include-resources-filter"
    :value="selectedEntities"
    :loading="loading"
    :label="$t('includeResources')"
    v-bind="$attrs"
    persistent-hint
    :filter="setSearchTerm"
    :items="resourceCategories"
    return-object
    autofocus
    auto-select-first
    item-text="canonical"
    item-value="canonical"
    multiple
    :hint="$t('includeResourceFilter')"
    @blur="setSearchTerm">
    <template #selection="">
      <span/>
    </template>
    <template #item="{ item, attrs, on }">
      <v-list
        :key="item"
        v-bind="attrs"
        flat
        :class="['category', { 'd-none' : !resourcesByCategory(item).length }]"
        subheader
        v-on="on">
        <v-subheader>
          <span>{{ item }}</span>
          <v-spacer/>
          <CyButton
            theme="primary"
            variant="tertiary"
            @click="selectCategory(item)">
            {{ $t('selectAll') }}
          </CyButton>
        </v-subheader>
        <CyInfraImportResourceItem
          v-for="resource in resourcesByCategory(item)"
          :key="resource.canonical"
          data-cy="resource-item"
          :resource="resource"
          is-autocomplete
          include-resources-filter
          @select-entity="selectResource"/>
      </v-list>
    </template>
    <template #no-data>
      <div
        v-if="hasAllResourcesSelected"
        class="text-align-center pa-2">
        <p class="mb-0">
          {{ $t('allResourcesSelected') }}
        </p>
      </div>
      <div
        v-else
        class="text-center pa-2">
        <p class="mb-0">
          {{ $t('noResourcesFound') }}
        </p>
        <p class="include-resources__missing-resource mb-0">
          {{ $t('missingResource') }}
          <a
            class="include-resources__contact-us"
            href="https://www.cycloid.io/contact-us"
            target="blank">
            {{ $t('contactUs') }}
          </a>
        </p>
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapGetters } from 'vuex'
import CyInfraImportResourceItem from '@/components/infra-import/resource-item'

export default {
  name: 'CyInfraImportIncludeResourcesFilter',
  components: {
    CyInfraImportResourceItem,
  },
  props: {
    selectedEntities: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    searchTerm: '',
  }),
  computed: {
    ...mapGetters('organization/infraImport', [
      'list',
    ]),
    resourceCategories () {
      return _(this.list('available.resources'))
        .map('category')
        .uniq()
        .sort()
        .value()
    },
    hasAllResourcesSelected () {
      return this.list('available.resources').length === this.selectedEntities.length
    },
  },
  methods: {
    setSearchTerm (category, searchTerm = '') {
      this.searchTerm = searchTerm.toLowerCase()

      return true
    },
    selectResource (resourceCanonical) {
      this.$emit('select-resource', resourceCanonical)
    },
    selectCategory (category) {
      const resourceCanonicals = _(this.list('available.resources'))
        .filter((resource) => resource.category === category)
        .map(({ canonical }) => canonical)
        .value()

      this.$emit('select-category', resourceCanonicals)
    },
    resourcesByCategory (category) {
      return _(this.list('available.resources'))
        .filter((resource) => resource.category === category)
        .filter((resource) => !_.find(this.selectedEntities, ['canonical', resource.canonical]))
        .filter(this.filterResourcesBySearchTerm)
        .value()
    },
    filterResourcesBySearchTerm ({ canonical = '', description = '', short_description: shortDescription = '' }) {
      return this.searchTerm
        ? (canonical.toLowerCase().includes(this.searchTerm) ||
          shortDescription.toLowerCase().includes(this.searchTerm) ||
          description.toLowerCase().includes(this.searchTerm))
        : true
    },
  },
  i18n: {
    messages: {
      en: {
        allResourcesSelected: 'All available resources were selected',
        contactUs: 'Contact us',
        includeResourceFilter: 'List of resources to import. The names are based on the Terraform provider documentation.',
        includeResources: 'Include resources',
        missingResource: 'Missing resource?',
        noResourcesFound: 'No resources found',
        selectAll: 'Select all',
      },
      es: {
        allResourcesSelected: 'Se seleccionaron todos los recursos disponibles',
        contactUs: 'Contacta con nosotros',
        includeResourceFilter: 'Lista de recursos para importar. Los nombres se basan en la documentación del proveedor de Terraform.',
        includeResources: 'Incluir recursos',
        missingResource: '¿Falta un recurso?',
        noResourcesFound: 'No se encontraron recursos',
        selectAll: 'Seleccionar todo',
      },
      fr: {
        allResourcesSelected: 'Toutes les ressources disponibles ont été sélectionnées',
        contactUs: 'Nous contacter',
        includeResourceFilter: 'Liste des ressources à importer. Les noms sont basés sur la documentation du fournisseur Terraform.',
        includeResources: 'Inclure des ressources',
        missingResource: 'Ressource manquante?',
        noResourcesFound: 'Aucune ressource trouvée',
        selectAll: 'Tout sélectionner',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-autocomplete__content {
  max-width: 480px;
  background-color: get-color("white");
}

.include-resources {
  &__missing-resource {
    color: get-color("grey", "dark-2");
  }

  &__contact-us {
    color: get-color("secondary");
    font-weight: $font-weight-bold;
  }
}

.v-select-list {
  max-width: 100%;

  ::v-deep {
    > div {
      padding: 0;
    }

    > .v-list-item {
      min-height: 0;

      &:hover {
        &::before {
          opacity: 0;
        }

        .v-list-item__title {
          color: get-color("primary") !important;
        }
      }
    }
  }
}

.category {
  min-width: 100%;
  padding: 0;

  .v-subheader {
    border-bottom: 1px solid get-color("grey", "light-1");
    font-size: 1rem;
    cursor: default;
  }
}
</style>
