import { render, staticRenderFns } from "./include-resources-filter.vue?vue&type=template&id=b13e5064&scoped=true&"
import script from "./include-resources-filter.vue?vue&type=script&lang=js&"
export * from "./include-resources-filter.vue?vue&type=script&lang=js&"
import style0 from "./include-resources-filter.vue?vue&type=style&index=0&id=b13e5064&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b13e5064",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VList } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VAutocomplete,VList,VSpacer,VSubheader})
