import { render, staticRenderFns } from "./wizard-external-backend.vue?vue&type=template&id=07ba5a6c&scoped=true&"
import script from "./wizard-external-backend.vue?vue&type=script&lang=js&"
export * from "./wizard-external-backend.vue?vue&type=script&lang=js&"
import style0 from "./wizard-external-backend.vue?vue&type=style&index=0&id=07ba5a6c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07ba5a6c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VDivider,VExpandTransition})
