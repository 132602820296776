<template>
  <div>
    <CyNotification
      slot="notification"
      :content="$t('variablesMessage')"
      no-bottom-margin/>

    <v-container class="mb-4">
      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="resourceSearchText"
            autocomplete="off"
            class="search-field"
            :label="$t('filterResourcesLabel')"
            :hint="$t('filterResourcesHint')"
            clearable
            persistent-hint>
            <v-icon slot="append">
              search
            </v-icon>
          </v-text-field>
        </v-col>
        <v-col cols="8">
          <v-text-field
            v-model="attrSearchText"
            class="search-field"
            :label="$t('filterAttrsLabel')"
            :hint="$t('filterAttrsHint')"
            clearable
            persistent-hint>
            <v-icon slot="append">
              search
            </v-icon>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="vars__list-headers">
        <v-col
          class="pb-0"
          cols="4">
          <div>{{ $t('Resources') }}</div>
        </v-col>
        <v-col
          class="pb-0"
          cols="8">
          <div>{{ $t('resourceAttrs') }}</div>
        </v-col>
      </v-row>
      <v-row class="vars__row mt-4">
        <v-col cols="4">
          <div :class="['elevation-2 vars__column']">
            <v-list class="vars__list">
              <CyInfraImportResourceItem
                v-for="resource in filteredResources"
                :key="resource.canonical"
                data-cy="vars-list-item"
                :class="{ 'vars__list-item--active': isResourceActive(resource.canonical), 'vars__list-item--attrs-selected': hasSelectedAttributes(resource.canonical) }"
                :resource="resource"
                :attrs-on-variable="variables[resource.canonical]"
                :active="isResourceActive(resource.canonical)"
                :ripple="false"
                :removable="false"
                @select-entity="selectResource"/>
            </v-list>
          </div>
        </v-col>
        <v-col cols="8">
          <div :class="['elevation-2 vars__column', { 'd-flex justify-center align-center': !activeResource }]">
            <template v-if="!!activeResource">
              <KeepAlive v-if="preserveCachedTrees">
                <tree
                  :key="activeResource"
                  :ref="'tree' + activeResource"
                  :data="parsedActiveSchema"
                  :filter="attrSearchText"
                  :options="{
                    checkbox: true,
                    filter: { emptyText: '' },
                    multiple: false,
                    propertyNames: {
                      text: 'text',
                      children: 'children',
                      required: 'required',
                      type: 'type',
                    },
                  }"
                  class="cy-tree vars__list vars__overflow"
                  @input="setActiveResource"
                  @node:clicked="handleNodeClick">
                  <template #default="{ node }">
                    <div class="cy-tree__text">
                      <span class="cy-tree__key">{{ node.text }}</span>
                      <span class="cy-tree__type">
                        {{ node.data.type.substr(4) }}
                      </span>
                      <CyTag
                        v-if="node.data.required"
                        class="ml-1"
                        variant="error">
                        required
                      </CyTag>
                    </div>
                  </template>
                </tree>
              </KeepAlive>
            </template>
            <div
              v-else
              class="d-flex flex-column align-center justify-center vars__zero-state">
              <v-icon
                size="64"
                class="vars__zero-state-image">
                mdi-code-tags
              </v-icon>
              <p class="text-center mb-1">
                {{ $t('variablesZeroState') }}
              </p>
              <p>
                <a
                  class="cy-link"
                  :href="$docLinks.infraImport.index"
                  target="_blank">
                  {{ $t('ReadMore') }}
                </a>
              </p>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row
        class="vars__clear-all"
        no-gutters>
        <v-col
          class="d-flex justify-end"
          cols="4">
          <a
            v-if="hasSelectedVariables"
            class="cy-link mr-4 mt-2"
            @click="() => isClearAllDialogVisible = true">
            {{ $t('forms.clearAll') }}
          </a>
        </v-col>
        <v-col cols="8"/>
      </v-row>
    </v-container>

    <v-divider/>

    <div class="d-flex justify-end pt-4">
      <slot name="actions">
        <CyButton
          variant="secondary"
          @click="$emit('previous')">
          {{ $t('forms.back') }}
        </CyButton>

        <CyButton
          data-cy="variables-btn"
          @click="$emit('next', variables)">
          {{ $t('forms.btnContinue') }}
        </CyButton>
      </slot>
      <div/>
    </div>

    <CyModal
      v-if="isClearAllDialogVisible"
      :header-title="$t('clearAllVariables')"
      :cancel-btn-func="() => isClearAllDialogVisible = false"
      :action-btn-func="clearSelectedVariables"
      :action-btn-text="$t('forms.clearAll')"
      action-btn-icon=""
      modal-type="delete"
      small>
      <p>
        <b>{{ $t('allProgressLost') }}</b><br>
        {{ $t('forms.areYouSure') }}
      </p>
    </CyModal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import CyInfraImportResourceItem from '@/components/infra-import/resource-item'
import LiquorTree from 'liquor-tree'

export default {
  name: 'CyInfraImportWizardVariables',
  components: {
    CyInfraImportResourceItem,
    [LiquorTree.name]: LiquorTree,
  },
  data: () => ({
    activeResource: null,
    attrSearchText: null,
    resourceSearchText: null,
    resources: [],
    parsedActiveSchema: [],
    variables: {},
    isClearAllDialogVisible: false,
    preserveCachedTrees: true,
  }),
  computed: {
    ...mapState('organization/infraImport', {
      stateEntities: (state) => state.entities,
      stateVariables: (state) => state.variables,
      availableResources: (state) => state.available.resources,
    }),
    ...mapGetters('organization/infraImport', ['list']),
    filteredResources () {
      const searchText = this.resourceSearchText
      return searchText
        ? _.filter(this.resources, ({
          canonical = '', short_description: shortDescription = '', description = '',
        }) => _.some([canonical, shortDescription, description], (searchProp) => searchProp.toLowerCase().includes(searchText.toLowerCase())))
        : this.resources
    },
    hasSelectedVariables () {
      return !_.$isEmpty(this.variables)
    },
  },
  watch: {
    activeResource (activeResource) {
      const recursiveMap = (schema, prevKey) =>
        _.map(schema, (val, key) => ({
          text: key,
          path: prevKey ? `${prevKey}.${key}` : key,
          data: {
            type: val.type,
            required: !val.optional,
          },
          ...(val.elem?.schema && { children: recursiveMap(val.elem.schema, key) }),
        }))
      this.parsedActiveSchema = recursiveMap(this.availableResources[activeResource].schema)
    },
  },
  mounted () {
    this.resources = _.$isEmpty(this.stateEntities)
      ? [...this.list('available.resources')]
      : [...this.stateEntities]

    this.variables = _.cloneDeep(this.stateVariables)
  },
  methods: {
    ...mapActions('organization/infraImport', [
      'GET_PROVIDER_RESOURCE_BY_ID',
    ]),
    clearSelectedVariables () {
      this.variables = {}
      this.preserveCachedTrees = false
      this.$nextTick(() => { this.preserveCachedTrees = true })
      this.isClearAllDialogVisible = false
    },
    getResourceFullPath (resource) {
      return resource.depth > 0
        ? `${this.getResourceFullPath(resource.parent)}.${resource.text}`
        : resource.text
    },
    handleNodeClick (node) {
      node.checked() ? node.uncheck() : node.check()
    },
    hasSelectedAttributes (resource) {
      return !_.$isEmpty(this.variables[resource])
    },
    isResourceActive (resource) {
      return this.activeResource === resource
    },
    async selectResource (canonical) {
      await this.GET_PROVIDER_RESOURCE_BY_ID({ resourceId: canonical })
      this.activeResource = canonical
    },
    setActiveResource ({ checked }) {
      const checkedResources = _.map(checked, (resource) => this.getResourceFullPath(resource))
      this.$set(this.variables, this.activeResource, checkedResources)
    },
  },
  i18n: {
    messages: {
      en: {
        allProgressLost: 'All variables chosen in this step will be deselected',
        clearAllVariables: 'Clear all variables',
        filterAttrsHint: 'Browse the list of resource attributes',
        filterAttrsLabel: 'Filter attributes',
        filterResourcesHint: 'Filter the list to find the desired resource',
        filterResourcesLabel: 'Filter resources',
        resourceAttrs: 'Resource Attributes',
        variablesMessage: 'Here, you may apply some attributes for each of your previously selected resources. These attributes will be available via StackForms when creating a project with this stack.',
        variablesZeroState: 'By default, @:InfraImport turns all of the resource attributes into variables. In this view you can choose which attributes will become variables. To do that, click on a resource from the list and select the desired attribute.',
      },
      es: {
        allProgressLost: 'Todas las variables elegidas en este paso serán deseleccionadas',
        clearAllVariables: 'Borrar todas las variables seleccionadas',
        filterAttrsHint: 'Examinar la lista de atributos de recursos',
        filterAttrsLabel: 'Filtrar atributos',
        filterResourcesHint: 'Filtrar la lista para encontrar el recurso deseado',
        filterResourcesLabel: 'Filtrar recursos',
        resourceAttrs: 'Atributos de recursos',
        variablesMessage: 'Aquí, puede aplicar algunos atributos para cada uno de sus recursos seleccionados previamente. Estos atributos estarán disponibles a través de StackForms al crear un proyecto con este stack.',
        variablesZeroState: 'De forma predeterminada, @:InfraImport convierte todos los atributos de recursos en variables. En esta vista puede elegir qué atributos se convertirán en variables. Para hacer eso, haga clic en un recurso de la lista y seleccione el atributo deseado.',
      },
      fr: {
        allProgressLost: 'Toutes les variables choisies à cette étape seront désélectionnées',
        clearAllVariables: 'Effacer toutes les variables sélectionnées',
        filterAttrsHint: 'Parcourir la liste des attributs de ressources',
        filterAttrsLabel: 'Attributs de filtre',
        filterResourcesHint: 'Filtrer la liste pour trouver la ressource souhaitée',
        filterResourcesLabel: 'Filtrer les ressources',
        resourceAttrs: 'Attributs de ressource',
        variablesMessage: `Ici, vous pouvez appliquer certains attributs pour chacune des ressources précédemment sélectionnées. Ces attributs seront disponibles via StackForms, lors de la création d'un projet avec cette stack.`,
        variablesZeroState: `Par défaut, @:InfraImport transforme tous les attributs de ressource en variables. Dans cette vue, vous pouvez choisir quels attributs deviendront des variables. Pour ce faire, cliquez sur une ressource dans la liste et sélectionnez l'attribut souhaité.`,
      },
    },
  },
}
</script>

<style lang="scss" scoped>
$variables-min-height: 400px;

.vars {
  &__column {
    height: 60vh;
    min-height: $variables-min-height;
    border-radius: 4px;
  }

  &__row {
    min-height: 416px;
    margin-top: 0;
    overflow-y: auto;
  }

  &__list-headers {
    color: get-color("grey", "dark-2");
    font-size: 1rem;
  }

  &__list {
    height: 60vh;
    min-height: $variables-min-height;
    max-height: 586px;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 4px;
  }

  &__overflow {
    overflow-y: auto;
  }

  &__list-item {
    &--active {
      box-shadow: map.get($box-shadow, "elevated");
    }
  }

  &__attribute {
    min-height: 29px;
    padding: 0 8px;
    border-bottom: 1px solid get-color("grey", "light-1");
    font-size: map.get($font-sizes, "sm");

    &--selected {
      border-bottom: 1px solid get-color("white");
      outline: 1px solid get-color("secondary", "light-1");
      outline-offset: -1px;
      background-color: get-color("secondary", "light-3");
    }
  }

  &__attribute-property {
    width: 100%;
    font-family: $font-family-code;

    &--required {
      color: get-color("error");
    }

    &--optional {
      color: get-color("secondary", "dark-1");
    }
  }

  &__clear-all {
    min-height: 29px;

    .cy-link {
      color: get-color("error");

      &:hover {
        color: get-color("accent");
      }
    }
  }

  &__zero-state {
    max-width: 360px;
    color: get-color("grey", "dark-2");
  }

  &__zero-state-image {
    max-width: 240px;
    margin-top: -24px;
  }
}

.cy-tree {
  ::v-deep .tree-content {
    height: 20px;
    margin-top: 2px;
  }
}
</style>
