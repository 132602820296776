<template>
  <div class="cy-combobox">
    <v-combobox
      v-bind="$attrs"
      ref="combobox"
      :value="value"
      :error-messages="errorMessages"
      :hint="hint"
      :persistent-hint="!_.isEmpty(hint)"
      :search-input.sync="searchInputBridge"
      :multiple="multiple"
      :class="['cy-combobox__input', {
        'cy-combobox__input--dropdown-hidden': !isDropdownEnabled,
        'required-field': required,
      }]"
      hide-selected
      @change="(items) => $emit('change', items)"
      @input="(items) => $emit('input', items)"
      @focus="$emit('focus')"
      @blur="$emit('blur')">
      <template #selection="data">
        <slot
          name="selection"
          v-bind="data.item">
          <CyTag
            :key="`${JSON.stringify(data.item)}-${data.index}`"
            class="cy-combobox__selection mr-1"
            :is-selected="data.selected"
            variant="primary"
            :icon-after="!disabled ? 'close' : ''"
            :small="small"
            :input-value="data.selected"
            :focusable="focusableItems"
            :disabled="data.disabled"
            @click-icon-after="removeItem(data.item)">
            {{ data.item }}
          </CyTag>
        </slot>
      </template>
      <template #item="{ item }">
        <slot
          name="item"
          v-bind="{ item }">
          <CyTag
            class="cy-combobox__selection mr-1"
            variant="primary"
            :small="small">
            {{ item }}
          </CyTag>
        </slot>
      </template>
      <template #message="{ message }">
        <span v-html="$sanitizeHtml(message)"/>
      </template>
    </v-combobox>
  </div>
</template>

<script>
export default {
  name: 'CyInputsCombobox',
  props: {
    value: {
      type: [String, Array],
      default: () => '',
    },
    hint: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    isDropdownEnabled: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    focusableItems: {
      type: Boolean,
      default: true,
    },
    searchInput: {
      type: String,
      default: '',
    },
    small: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    searchInputBridge: {
      get () {
        return this.searchInput
      },
      set (searchInput) {
        this.$emit('update:searchInput', searchInput)
      },
    },
  },
  methods: {
    removeItem (item) {
      const newValue = typeof this.value === 'string' ? '' : _.pull([...this.value], item)
      this.$emit('input', newValue)
    },
  },
}
</script>

<style lang="scss" scoped>
.cy-combobox {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: auto;

  &__input {
    ::v-deep {
      .tag {
        margin: 0 0 4px;
        cursor: default;

        &__icon {
          cursor: pointer;
        }
      }

      .v-select__selections {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        padding-top: 6px;

        input {
          align-self: flex-end;
          padding-top: 0;
          padding-bottom: 2px;
          color: get-color("primary");
          font-size: 12px;
          line-height: 180%;
        }
      }
    }

    &--dropdown-hidden {
      ::v-deep .v-input__icon {
        display: none;
      }
    }
  }
}
</style>
