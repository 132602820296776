<template>
  <div class="cy-wizard-project-details">
    <div
      :v-if="!loading"
      class="cy-wizard-project-details__container">
      <h3 class="mt-7 mb-4">
        {{ $t('createProject') }}
      </h3>

      <div>
        <v-text-field
          v-model.trim="$v.project.name.$model"
          :label="$t('projectName')"
          :error-messages="nameErrors"
          :counter="$static.SLUG_LIMIT"
          required
          class="required-field"
          @input="setCanonicalInput"
          @blur="$v.project.name.$touch()"/>

        <v-text-field
          v-model="$v.project.canonical.$model"
          :label="$t('canonicalProjectName')"
          :hint="$t('canonicalUniqueId')"
          :error-messages="canonicalErrors"
          :value="project.canonical"
          persistent-hint
          required
          class="required-field mb-4"
          disabled/>

        <v-textarea
          v-model="project.description"
          :label="$t('forms.fieldDescription')"
          :hint="$t('projectDescription')"
          :rows="4"
          persistent-hint
          class="description mb-4"/>

        <CyInputsConfigRepositorySelect
          v-model="$v.project.configRepository.$model"
          :error-messages="configRepositoryErrors"
          :label="$t('ConfigRepository')"
          autoselect-lone
          required
          class="config-repository-select required-field mb-4 pt-4"
          @blur="$v.project.configRepository.$touch()"/>

        <v-combobox
          v-model="project.environment.canonical"
          :search-input.sync="envInput"
          :label="$t('forms.fieldOrgEnv')"
          :items="_.map(availableEnvironments, 'canonical')"
          :error-messages="environmentErrors"
          hide-selected
          persistent-hint
          dense
          required
          class="required-field environments-selector ma-0 pa-0"
          @blur="$v.project.environment.$touch()"/>

        <label v-text="$t('icon')"/>
        <CyInputsIconPicker
          class="mt-1"
          :env-canonical="project.environment.canonical"
          :color-name.sync="project.environment.color"
          :icon.sync="project.environment.icon"/>
      </div>
    </div>

    <v-divider class="mb-6 mt-4"/>

    <div class="cy-wizard-project-details__actions">
      <slot
        name="actions"
        :is-form-valid="!$v.$invalid">
        <CyButton
          variant="secondary"
          @click="$emit('previous')">
          {{ $t('forms.back') }}
        </CyButton>

        <CyButton
          :disabled="$v.$invalid"
          @click="$emit('next', project)">
          {{ $t('forms.btnContinue') }}
        </CyButton>
      </slot>
    </div>
  </div>
</template>

<script>
import { SLUG_LIMIT } from '@/utils/plugins/get-slug'
import { mapGetters, mapState, mapActions } from 'vuex'
import { required, maxLength } from 'vuelidate/lib/validators'
import REGEX from '@/utils/config/regex'
import CyInputsConfigRepositorySelect from '@/components/inputs/config-repository-select'
import CyInputsIconPicker from '@/components/inputs/icon-picker'

export default {
  name: 'CyInfraImportWizardProjectInformation',
  components: {
    CyInputsConfigRepositorySelect,
    CyInputsIconPicker,
  },
  validations () {
    const { isCanonicalUnique } = this
    return {
      project: {
        name: {
          required,
          maxLength: maxLength(SLUG_LIMIT),
          alphaNumeric: (val) => _.isEmpty(val) || REGEX.PROJECT_NAME.test(val),
          isUnique: () => isCanonicalUnique,
        },
        canonical: {
          required,
          isValidCanonical: (can) => REGEX.PROJECT_NAME.test(can),
          isUnique: () => isCanonicalUnique,
        },
        configRepository: {
          required,
        },
        environment: {
          required,
          isNameValid: (env) => REGEX.ENV_NAME.test(env.canonical),
        },
      },
    }
  },
  data: () => ({
    loading: true,
    envInput: '',
    project: {
      name: null,
      canonical: null,
      description: null,
      configRepository: null,
      environment: {
        color: '',
        icon: '',
      },
    },
  }),
  computed: {
    ...mapGetters('organization', [
      'hasAvailable',
    ]),
    ...mapState('organization', {
      availableEnvironments: (state) => state.available.environments,
      projects: (state) => state.available.projects,
    }),
    ...mapState('organization/infraImport', {
      stateProject: (state) => state.project,
    }),
    $static: () => ({
      SLUG_LIMIT,
    }),
    nameErrors () {
      const { $dirty, required, maxLength, alphaNumeric, isUnique } = this.$v.project.name
      const errors = []
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      if (!alphaNumeric) errors.push(this.$t('forms.fieldNotAlphaNum'))
      if (!maxLength) errors.push(this.$t('forms.fieldMaxLength', { number: SLUG_LIMIT }))
      if (!isUnique) errors.push(this.$t('forms.projectCanonicalAlreadyExists'))
      return errors
    },
    canonicalErrors () {
      const { $dirty, required, isValidCanonical, isUnique } = this.$v.project.canonical
      const errors = []
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      if (!isValidCanonical) errors.push(this.$t('invalidCanonical'))
      if (!isUnique) errors.push(this.$t('forms.projectCanonicalAlreadyExists'))
      return errors
    },
    configRepositoryErrors () {
      const { $dirty, required } = this.$v.project.configRepository
      const errors = []
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
    environmentErrors () {
      const { $dirty, required, isNameValid } = this.$v.project.environment
      const errors = []
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      if (!isNameValid && !_.isEmpty(this.project.environment.canonical)) errors.push(this.$t('environment.nameInvalid'))
      return errors
    },
    isCanonicalUnique () {
      const projects = _.map(this.projects, 'canonical')
      return !_.includes(projects, this.project.canonical)
    },
  },
  async mounted () {
    this.$v.$reset()

    await Promise.all([
      ...(!this.hasAvailable('environments') ? [this.FETCH_AVAILABLE({ keyPath: 'environments' })] : []),
      this.FETCH_AVAILABLE({ keyPath: 'projects' }),
    ])

    this.project = _.merge(this.project, this.stateProject)

    this.loading = false
  },
  methods: {
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
    setCanonicalInput (e) {
      this.$v.project.canonical.$model = e.trim().split(' ').join('_').toLowerCase()
    },
  },

  i18n: {
    messages: {
      en: {
        canonicalProjectName: 'Canonical Project name',
        canonicalUniqueId: 'This is a unique identifier for your project.',
        createProject: 'Create a Project',
        invalidCanonical: 'This canonical is invalid',
        projectDescription: 'Project description',
        projectName: 'Project name',
      },
      es: {
        canonicalProjectName: 'Nombre del proyecto canónico',
        canonicalUniqueId: 'Éste es un identificador único para su proyecto',
        createProject: 'Crear un Proyecto',
        invalidCanonical: 'Este canónico es inválido',
        projectDescription: 'Descripción del Proyecto',
        projectName: 'Nombre del proyecto',
      },
      fr: {
        canonicalProjectName: 'Nom du projet canonique',
        canonicalUniqueId: 'Ceci est un identifiant unique pour votre projet',
        createProject: 'Créer un Projet',
        invalidCanonical: 'Cet indentifiant est invalide',
        projectDescription: 'Description du projet',
        projectName: 'Nom du projet',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
$width: 450px;

.cy-wizard-project-details {
  label {
    color: get-color("grey", "dark-2");
  }

  &__container {
    width: $width;
    min-width: $width;
    height: 100%;

    ::v-deep .v-select__slot {
      max-height: 30px;
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
  }
}

.icon-picker {
  position: relative;

  ::v-deep .v-card {
    bottom: 40px;
  }
}
</style>
