<template>
  <div class="text-center card-container">
    <v-card
      :class="['card', {
        'card--coming-soon': isComingSoon,
        'card--clickable': !isComingSoon,
        'card--is-selected': isSelected,
      }]"
      @click.native="onSelectClick">
      <v-avatar
        class="provider-avatar"
        size="140">
        <CyIconCredential
          :type="provider.canonical"
          no-margin/>
      </v-avatar>
      <v-card-title justify-center>
        <h3 class="card-title mb-0">
          {{ provider.abbreviation }}
        </h3>
      </v-card-title>
      <v-card-text class="pt-4">
        <SimpleBar class="description">
          {{ $t(`description.${provider.canonical}`) }}
        </SimpleBar>
      </v-card-text>
      <v-card-actions class="actions">
        <CyTag
          v-if="isComingSoon"
          variant="accent"
          small
          coming-soon>
          {{ $t('comingSoon') }}
        </CyTag>
        <template v-else>
          <CyButton
            v-if="singleSelect"
            theme="secondary"
            variant="secondary">
            {{ $t('forms.btnSelect') }}
          </CyButton>
          <v-switch
            v-else
            :input-value="isSelected"
            color="secondary"
            :class="['switch', { 'switch--is-selected' : isSelected }]"/>
        </template>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'CyInfraImportProviderCard',
  props: {
    provider: {
      type: Object,
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    singleSelect: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isComingSoon () {
      return !this.provider.isSupported
    },
  },
  methods: {
    onSelectClick () {
      if (this.isComingSoon) return
      this.$emit(this.isSelected ? 'deselected' : 'selected', this.provider)
    },
  },
  i18n: {
    messages: {
      en: {
        description: {
          aws: 'Amazon Web Services offers reliable, scalable, and inexpensive cloud computing services. Free to join, pay only for what you use.',
          azurerm: 'Microsoft Azure gives the freedom to build, manage and deploy applications on a massive, global network using your favourite tools and frameworks.',
          flexibleengine: 'Flexible Engine is a worldwide Cloud Public solution operated by Orange Business Services, based on our partner Huawei Openstack™ distribution',
          google: `Tap into Google's world-class infrastructure and robust set of solutions to build, operate, and grow your business in today's complex, multi-cloud environment.`,
          openstack: 'OpenStack is a cloud operating system that controls large pools of compute, storage, and networking resources throughout a datacenter',
          vault: 'Vault secures, stores, and tightly controls access to tokens, passwords, certificates, @:APIKeys, and other secrets in modern computing',
          vsphere: `VMware vSphere is VMware's virtualization platform, which transforms data centers into aggregated computing infrastructures.`,
        },
      },
      es: {
        description: {
          aws: 'Descubra la infraestructura que ofrece Amazon Web Services, servicios de alojamiento, servicios de informática y servicios para empresas en la nube. Regístrese gratis y pague sólo por su uso.',
          azurerm: 'Microsoft Azure brinda la libertad de crear, administrar e implementar aplicaciones en una red global masiva utilizando sus herramientas y marcos favoritos.',
          flexibleengine: 'Flexible Engine es una solución mundial de Public Cloud operada por Orange Business Services, basada en la distribución de nuestro socio Huawei, Openstack ™.',
          google: 'Aproveche la infraestructura de primera y el sólido conjunto de soluciones de Google para crear, operar y expandir su negocio en el complejo entorno multinube actual.',
          openstack: 'OpenStack es un sistema operativo en la nube capaz de controlar una gran cantidad de recursos de servidor, red y almacenamiento distribuidos en el centro de datos',
          vault: 'Vault securiza, almacena y controla estrictamente el acceso a tokens, contraseñas, certificados, @:APIKeys y otros secretos en la informática moderna.',
          vsphere: `VMware vSphere es la plataforma de virtualización de VMware, que transforma los centros de datos en infraestructuras informáticas agregadas.`,
        },
      },
      fr: {
        description: {
          aws: `Inscrivez-vous gratuitement aux services Amazon AWS de cloud computing fiables, évolutifs et économiques et profitez de la puissance de calcul Amazon ainsi que la flexibilité du paiement à l'utilisation.`,
          azurerm: 'Microsoft Azure offre la possibilité de créer, gérer et déployer des applications sur un vaste réseau mondial en utilisant vos outils et frameworks préférés.',
          flexibleengine: 'Flexible Engine est une solution Cloud publique mondiale exploitée par Orange Business Services, basée sur notre partenaire de distribution Huawei Openstack ™.',
          google: `Exploitez tout le potentiel de l'infrastructure de pointe et de l'ensemble des solutions fiables de Google permettant de créer, de faire fonctionner et de développer votre entreprise dans l'environnement multicloud complexe moderne.`,
          openstack: `OpenStack est un système d'exploitation de cloud capable de contrôler un grand nombre de ressources serveur, réseau et stockage réparties dans le data center`,
          vault: `Vault sécurise, stocke et contrôle étroitement l'accès aux tokens, mots de passe, certificats, @:APIKeys et autres secrets de l'informatique moderne`,
          vsphere: `VMware vSphere est la plate-forme de virtualisation de VMware, qui transforme les centres de données en infrastructures informatiques agrégées.`,
        },
      },
    },
  },
}
</script>

<style lang="scss" scoped>
  $switch-size: 1.5em;

  ::v-deep .v-card {
    &__title {
      padding: 16px 0 0;
    }

    &__actions {
      display: flex;
      position: absolute;
      bottom: -1.25em;
      justify-content: center;
      width: 100%;
      height: 3em;

      .switch {
        flex: 0 1 auto;
        width: auto;
        margin: auto;

        .v-input--switch__thumb {
          top: calc(50% - #{math-div($switch-size, 2)});
          width: $switch-size;
          height: $switch-size;
        }

        .v-input--switch__track {
          top: calc(50% - #{math-div($switch-size, 2)});
          height: $switch-size;
          border-radius: 1em;
          opacity: 1;
          color: get-color("grey") !important;
        }

        .v-input--selection-controls__ripple {
          top: calc(50% - #{$switch-size});
          left: - #{math-div($switch-size, 2)};
        }

        &--is-selected .v-input--switch__track {
          color: #83cfcf !important;
        }
      }
    }
  }

  .card {
    width: 200px;
    height: 100%;
    min-height: 280px;
    margin: 0 auto;
    border-radius: 5px;

    .provider-avatar {
      margin-top: -80px;
      margin-bottom: 8px;
      transition: all 0.2s ease-out;
      border-radius: 50% !important;
      background-color: get-color("white");
      box-shadow: 0 1px 5px get-color("grey", "dark-2", 0.2);

      ::v-deep .credential-icon {
        width: 80px;
        margin: 0;
        border-radius: 0;
      }
    }

    &--clickable {
      transition: all 0.2s ease-out;
      cursor: pointer;

      &:hover,
      &.card--is-selected {
        box-shadow:
          0 5px 5px -3px get-color("black", $alpha: 0.8),
          0 8px 10px 1px get-color("black", $alpha: 0.14),
          0 3px 14px 2px get-color("black", $alpha: 0.12);

        .btn--hover {
          border: none;
          background-color: get-color("secondary") !important; /* 💢 vuetify override */
          color: white !important; /* 💢 vuetify override */
        }

        .provider-avatar {
          box-shadow: 0 -3px 10px 0 get-color("black", $alpha: 0.14);
        }
      }
    }

    &--coming-soon .actions {
      position: relative;
      bottom: 0;
      align-items: flex-end;
      height: 1em;
    }

    &-title {
      width: 100%;
      margin-top: -10px;
      font-size: 20px;
    }

    &-container {
      position: relative;
      padding-top: 80px;

      .dev-only {
        display: flex;
        position: absolute;
        z-index: 1;
        top: 7.25em;
        left: calc(50% - 8em);
        align-items: center;
        justify-content: center;
        width: 2em;
        height: 2em;
        border: 2px solid get-color("white");
        border-radius: 50%;
        background: get-color("secondary");
        box-shadow: 0 0 4px 1px get-color("black", $alpha: 0.2);
        color: get-color("white");
        font-size: 0.85rem;
      }
    }

    .description {
      height: 150px;
      margin-bottom: 8px;
      padding: 0 0.25em;
      white-space: normal;
    }
  }

  .default-letter {
    position: absolute;
    color: get-color("primary");
    font-size: 5em;
    font-weight: bold;
    text-shadow:
      -3px -3px 0 get-color("white"),
      3px -3px 0 get-color("white"),
      -3px 3px 0 get-color("white"),
      3px 3px 0 get-color("white");
    text-transform: uppercase;
  }
</style>
